/*-------------exmaple------------

@mixin shadow {
}

*/
/*primary colors: Use for CTAs, Focused/Active states*/
/*icon: Use for icons*/
/*-------------exmaple------------

$icon-dark: #111;
$icon-light: #fff;

*/
/*extra colors*/
/*typography*/
/*default*/
#root {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

body {
  margin: 0;
  padding: 50px 0;
  font-family: "Inter", "Oxygen", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  text-align: center;
}

h2 {
  font-size: 20px;
  text-align: center;
}

.container {
  width: 60vw;
}
@media only all and (max-width: 768px) {
  .container {
    width: 90vw;
  }
}

.box {
  padding: 28px;
  background-color: rgba(15, 193, 217, 0.2);
  border: 1px solid #0FC1D9;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
}

.mini-form {
  width: 55%;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: auto 10px 1fr;
  grid-template-columns: auto 1fr;
  gap: 10px;
}

.result-container {
  margin-top: 20px;
  text-align: center;
}

.result {
  margin-top: 20px;
  font-size: 32px;
  font-weight: bold;
}

.btn-primary {
  margin-top: 25px;
  margin-right: 12px;
  padding: 10px 40px;
  background-color: #fff;
  border: 1px solid #000;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  font-size: 16px;
  transition: all 0.5s ease 0s;
  -webkit-transition: all 0.5s ease 0s;
  -moz-transition: all 0.5s ease 0s;
  -ms-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
}
.btn-primary:hover {
  background-color: rgba(15, 193, 217, 0.2);
  border-color: #0FC1D9;
  cursor: pointer;
}

.btn-secondary {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 8px;
  background-color: transparent;
  border: none;
  font-size: 16px;
  transition: all 0.5s ease 0s;
  -webkit-transition: all 0.5s ease 0s;
  -moz-transition: all 0.5s ease 0s;
  -ms-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
}
.btn-secondary svg path {
  transition: all 0.5s ease 0s;
  -webkit-transition: all 0.5s ease 0s;
  -moz-transition: all 0.5s ease 0s;
  -ms-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
}
.btn-secondary:hover {
  color: #0FC1D9;
  cursor: pointer;
}
.btn-secondary:hover svg path {
  stroke: #0FC1D9;
}

.form-control {
  width: 100%;
  min-height: 25px;
  padding-left: 10px;
  padding-right: 10px;
  border: 1px solid #DCDCDC;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
}

.error-validation {
  margin-top: 5px;
  color: #D40000;
  transition: all 0.5s ease 0s;
  -webkit-transition: all 0.5s ease 0s;
  -moz-transition: all 0.5s ease 0s;
  -ms-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
}